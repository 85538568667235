import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { EmpUserInfo, MenuGroupInfo, MenuInfo } from '../interface/emp-user-management';
import { ActionTypeEnum, ApiResponse, PagableData } from '../interface/common';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { routes as routes_user_bidding, menu_id as menu_id_user_bidding } from '../../pages/user-bidding/user-bidding-routing.module';
import { routes as routes_company } from '../../pages/company/company-routing.module';
@Injectable()
export class CommonService {
  domain: string;
  public routesUserbidding = [];
  public routesCompany = [];
  constructor(private http: HttpClient) {
    this.domain = environment.apiUrl;
  }

  getPermissionMenu(): Observable<ApiResponse<MenuInfo[]>> {
    const uri = `permission-menu`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<MenuInfo[]>>(url);
  }

  getPermissionGroupMenu() {
    const uri = `permission-menu-group`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<MenuGroupInfo[]>>(url);
  }

  getPermission(menu_id?: number, action_type?: number) {
    //action_type 1=ดู 2=เพิ่ม/แก้ไข 3=ลบ
    const uri = `permission-menu-enable/${menu_id}/${action_type}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<boolean>>(url);
  }

}
