import { ContactUsStatus } from "./../interface/contact-us";
import { routes } from "../../auth/auth-routing.module";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, delay, map, retry, tap } from "rxjs/operators";
import { da } from "date-fns/locale";


@Injectable()
export class BookingService {
  private _loading = new BehaviorSubject<boolean>(true);

  private url = environment.apiUrl;

  constructor(private http: HttpClient,
              private route: Router) {

    this._loading.next(false);
  }

  getBookingList(data) {
    return this.http.post(this.url + `/booking_and_po/bookingList`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  addBooking(data){
    return this.http.post(this.url + `/booking_and_po/addNewBooking`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  addBookingOption(data){
    return this.http.post(this.url + `/booking_and_po/addNewVehOption`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  addPaymentHistory(data){
    return this.http.post(this.url + `/booking_and_po/addNewPaymentHistory`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  addOP(data){
    return this.http.post(this.url + `/booking_and_po/addNewPo`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  editBooking(data){ 
    return this.http.put(this.url + `/booking_and_po/editBooking/` + data.bk_bid_id, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  addBooking_log(data){
    return this.http.post(this.url + `/booking_and_po/addBookingLog/`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(400),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )  
  }

  editPaymentHistory(data){
    return this.http.put(this.url + `/booking_and_po/editPaymentHistory/` + data.pay_his_id, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  changePaymentFinanceToCash(id){
    return this.http.put(this.url + `/booking_and_po/changePaymentFinanceToCash/` + id, null)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  changePaymentCashToFinance(data, id){
    return this.http.put(this.url + `/booking_and_po/changePaymentCashToFinance/` + id, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )   
  }

  getBookingPDF(id){
    return this.http.get(this.url + '/booking_and_po/bookingPDF/' + id, { responseType: 'arraybuffer'});
  }

  deletePaymentHistory(id){
    return this.http.delete(this.url + '/booking_and_po/deletePaymentHistory/' + id)
  }

  deleteBookOption(id){
    return this.http.delete(this.url + '/booking_and_po/deleteBookVehOption/' + id)
  }

  getBookingById(id){
    return this.http.get(this.url + '/booking_and_po/bookingById/' + id)
  }

  getBookingOptionById(id){
    return this.http.get(this.url + '/booking_and_po/bookingOptionById/' + id)
  }
  
  getBookingPaymentHisById(id){
    return this.http.get(this.url + '/booking_and_po/bookingPaymentHistoryById/' + id)
  }
  getBookingStatus(){
    return this.http.get(this.url + '/booking_and_po/bookingStatus')
  }

  getBookingStatus4(){
    return this.http.get(this.url + '/booking_and_po/bookingStatus4')
  }

  getPoList(data) {
    return this.http.post(this.url + `/booking_and_po/poList`, data).pipe(
      tap(() => this._loading.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading.next(false)
        return res;
      })
      )
  }

  getPoStatus(){
    return this.http.get(this.url + '/booking_and_po/poStatus')
  }

  poStatusbyId(data: number){
    return this.http.post(this.url + '/booking_and_po/poStatusById',data)
  }

  getPoById(id: number, bk: number,sfi : number,status : number,type : number){
    return this.http.get(this.url + `/booking_and_po/poById/${id}/${bk}/${sfi}/${status}/${type}`)
  }

  updatePo(data) {
    return this.http.put(this.url + "/booking_and_po/updatePo", data);
  }

  getPoPDF(id){
    return this.http.get(this.url + '/booking_and_po/pdfPo/' + id, { responseType: 'arraybuffer'});
  }

  getDeliverySlipPDF(data){
    return this.http.post(this.url + '/booking_and_po/pdfDeliverySlip',data ,{ responseType: 'arraybuffer'});
  }

}