// ข้อมูลผู้ใช้งานระบบ

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortDirection } from "../../@theme/directives/sort.directive";
import {State } from "../data/complete";


export interface Mock {
    id: number;
    veh_status: string;
    branch: string;
    chassis_no:string;
    lisen: string;
    sku: string;
    source: string;
    type_car: string;
    year_bu: string;
    year: string;
    brand: string;
    model: string;
    submodel: string;
    grade: string;
    mi: string;
    start_price: string;
    reserve_price: string;
    contract_no: string;
    contract_date: string;
    date_sys: string;
    update_datetime: string
  }
  const MOCKS :Mock[] = [
    {
      id: 1,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },
    {
      id: 2,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },
    {
      id: 3,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },
    {
      id: 4,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },
    {
      id: 5,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },
    {
      id: 6,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    },    {
      id: 7,
      veh_status: null,
      branch: null,
    chassis_no:null,
    lisen: null,
    sku: null,
    source: null,
    type_car: null,
    year_bu: null,
    year: null,
    brand: null,
    model: null,
    submodel: null,
    grade: null,
    mi: null,
    start_price: null,
    reserve_price: null,
    contract_no: null,
    contract_date: null,
    date_sys: null,
    update_datetime: null,
    }
  ]
  interface SearchResult {
    data: Mock[];
    total: number;
  }
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Mock[], column: any, direction: string): Mock[]{
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable()
export class KService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<Mock[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 5,
    sortColumn: "",
    sortDirection: "",
  };
  constructor() {
    // console.log('called check', this._search$);
    
    this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._data$.next(result.data);
        this._total$.next(result.total);
      });
  
      this._search$.next();
      
  }
  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set sortColumn(sortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {sortColumn, sortDirection, pageSize, page} = this._state;

    // 1. sort
    let data = sort(MOCKS, sortColumn, sortDirection);

    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({data, total});
  }
}
