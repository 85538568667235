import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { delay, map , tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MainTenanceService {
    url = environment.apiUrl;
    constructor(
        private http: HttpClient
    ) {}

    getMaintenance(query_param) {
        return this.http.get(this.url + `/mainteance${query_param}`);
    }

    addMaintenance(data) {
        return this.http.post(this.url + `/mainteance`, data);
    }

    delMaintenance(data) {
        return this.http.post(this.url + `/del-list-mainteance`, data);
    }

    sellerFinance() {
        return this.http.get(this.url + `/maintenance-finance`);
    }
}