// ข้อมูลผู้ใช้งานระบบ

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap,map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SortDirection } from "../../@theme/directives/sort.directive";

@Injectable()
export class CashService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {

  }
  getTransferMoney(data) {
    return this.http.post(this.url + "/transfer-money/list", data).pipe(
      
      tap(() => this._loading$.next(true)
      
      ),
      delay(200),
      
      map((res: any) => {
        
        this._loading$.next(false)
       
        return res;
      })
      )
    }
  getTransferMoneyById(id: number) {
    return this.http.get(this.url + `/transfer-money/${id}`)
  }

  getAuctionByTransferMoney(id: number) {
    return this.http.get(this.url + `/transfer-money/plan_auctionlist/${id}`)
  }

  updateTransferMoney(data: any, id: number) {
    return this.http.put(this.url + `/transfer-money/${id}`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  addTransferMoney(data: any) {
    return this.http.post(this.url + `/transfer-money`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  docGroup1() {
    return this.http.get(this.url + '/transfer-money/doc1/group1')
  }
  docGroup2() {
    return this.http.get(this.url + '/transfer-money/doc2/group2')
  }
  planAuctionByName() {
    return this.http.get(this.url + '/plan_auction/name')
  }
  get loading$() { return this._loading$.asObservable(); }

}
