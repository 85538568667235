// ตั้งค่าแอปพลิเคชัน

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class PromotionService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {}

  promotion() {
    return this.http.get(this.url + `/promotion`);
  }

  promotionList(data) {
    return this.http.post(this.url + `/promotion/list`,data);
  }

  getpromotionById(id: number) {
    return this.http.get(this.url + `/promotion/${id}`)
  }


  updatePromotion(data: any, id: number) {
    return this.http.put(this.url + `/promotion/${id}`, data)
  }

 
  addPromotion(data :any){
    console.log(data);
    return this.http.post(this.url + `/promotion`,data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }
}
