// ข้อมูล booking
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortDirection } from "../../@theme/directives/sort.directive";
import {State } from "../data/complete";


export interface Mock {
    id: number;
    book_no: string;
    cus_id: string
    firstname: string;
    phone: string;
    cus_name: string;
    bid_id: string;
    finance: string;
    status: string;
    lisen: string;
    chassis_no: string;
    brand: string;
    model: string;
    submodel: string;
    year: string;
    year_bu: string;
    price: string;
    create_by: string;
    create_datetime: string;
    update_by: string;
    update_datetime: string;
    auto: string;
  }
  const MOCKS :Mock[] = [
    {
      id: 1,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "วศิน สุรวิพงษ์",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 2,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "อัจฉรา สดใส",
      create_datetime: "15/07/2021 20:00:00",
      update_by: "นรา ศาสตร์ศรี",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 3,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
    {
      id: 4,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
    {
      id: 5,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "วศิน สุรวิพงษ์",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 6,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "อัจฉรา สดใส",
      create_datetime: "15/07/2021 20:00:00",
      update_by: "นรา ศาสตร์ศรี",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 7,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
    {
      id: 8,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
    {
      id: 9,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "วศิน สุรวิพงษ์",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 10,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "อัจฉรา สดใส",
      create_datetime: "15/07/2021 20:00:00",
      update_by: "นรา ศาสตร์ศรี",
      update_datetime: "15/07/2021 20:00:00",
      auto: "-",
    },
    {
      id: 11,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
    {
      id: 12,
      book_no: null,
      cus_id: null,
      firstname: null,
      phone: null,
      cus_name: null,
      bid_id: "P21082901",
      finance: null,
      status: null,
      lisen: null,
      chassis_no: null,
      brand: null,
      model: null,
      submodel: null,
      year: null,
      year_bu: null,
      price: null,
      create_by: "-",
      create_datetime: "-",
      update_by: "-",
      update_datetime: "-",
      auto: "Auto Canceled",
    },
  ]
  interface SearchResult {
    data: Mock[];
    total: number;
  }
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Mock[], column: any, direction: string): Mock[]{
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable()
export class IService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<Mock[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 5,
    sortColumn: "",
    sortDirection: "",
  };
  constructor() {
    // console.log('called check', this._search$);
    
    this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._data$.next(result.data);
        this._total$.next(result.total);
      });
  
      this._search$.next();
      
  }
  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set sortColumn(sortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {sortColumn, sortDirection, pageSize, page} = this._state;

    // 1. sort
    let data = sort(MOCKS, sortColumn, sortDirection);

    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({data, total});
  }
}
