import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { da } from "date-fns/locale";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class ImgLoginService{
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    this._loading$.next(false);
  }

  getSplashscreenList(data:any){
    return this.http.post(this.url + `/adVehicleBidding/list`,data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );

  }
  getSplashscreenById(id:number){
    return this.http.get(this.url+`/adVehicleBidding/${id}`);
  }

  adSplashscreen(data :any){
    console.log(data);
    return this.http.post(this.url + `/adVehicleBidding/add`,data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  updateSplashscreen(id:number,data:any){
   // console.log(id,data);
    return  this.http.put(this.url + `/adVehicleBidding/update/${id}`,data)
    .pipe(tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );


  }

  get loading$() { return this._loading$.asObservable(); }



}
