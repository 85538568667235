import { ContactUsStatus } from "./../interface/contact-us";
import { routes } from "../../auth/auth-routing.module";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, delay, map, retry, tap } from "rxjs/operators";

@Injectable()
export class QAndAService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    this._loading$.next(false);
  }

  getQandAlist(data) {
    return this.http.post(this.url + "/content_bidding/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  addQandA(data: any) {
    let res = this.http.post(this.url + `/content_bidding/add`, data);

    // console.log(res);
    return res;
  }

  updateQAndA(id,data: any) {
    let res =  this.http
      .put(this.url + `/content_bidding/edit/${id}`, data)
      .pipe(tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
      // console.log(res);
      return(res);
  }



  getDetail(id) {
    return this.http.post(this.url + `/content_bidding/detail`, {
      content_bid_id: id,
    });
  }


  //webview
  getQandAWebviewlist(data) {
    return this.http.post(this.url + "/webview/content_bidding/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }
  getWebviewDetail(id) {
    return this.http.post(this.url + `/webview/content_bidding/detail`, {
      content_bid_id: id,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  get loading$() {
    return this._loading$.asObservable();
  }
}
