// ข้อมูลผู้ใช้งานระบบ

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortDirection } from "../../@theme/directives/sort.directive";
import {State } from "../data/complete";


export interface Mock {
    id: number;
    lisen: string;
    provice: string;
    mi: string;
    branch_service: string;
    date_service: string;
    detail: string;
    create_datetime: string;
  }
  const MOCKS :Mock[] = [
    {
      id: 1,
      lisen: "กข 1234",
      provice: "กรุงเทพ",
      mi: "122231",
      branch_service: "อีซูซุสยาม ซิตี้ ลำลูกกา จำกัด",
      date_service: "31/12/2021",
      detail: "ซ่อมบำรุง",
      create_datetime: "23/08/2021 10:45:34"
    },
    {
      id: 2,
      lisen: "คช 3213",
      provice: "กรุงเทพ",
      mi: "122,000",
      branch_service: "อีซูซุสยาม ซิตี้ ลำลูกกา จำกัด",
      date_service: "31/12/2021",
      detail: "ซ่อมบำรุง",
      create_datetime: "23/08/2021 10:45:34"
    },
    {
      id: 3,
      lisen: "กข 1234",
      provice: "กรุงเทพ",
      mi: "101,231",
      branch_service: "โตโยต้าพิษณุโลก ผู้จำหน่ายโตโยต้า จำกัดสำนักงานใหญ่",
      date_service: "31/12/2021",
      detail: "ซ่อมบำรุง",
      create_datetime: "23/08/2021 10:45:34"
    },
    {
      id: 4,
      lisen: "กข 1234",
      provice: "กรุงเทพ",
      mi: "101,231",
      branch_service: "โตโยต้าพิษณุโลก ผู้จำหน่ายโตโยต้า จำกัดสำนักงานใหญ่",
      date_service: "31/12/2021",
      detail: "ซ่อมบำรุง",
      create_datetime: "23/08/2021 10:45:34"
    },
  ]
  interface SearchResult {
    data: Mock[];
    total: number;
  }
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Mock[], column: any, direction: string): Mock[]{
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable()
export class LService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<Mock[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 5,
    sortColumn: "",
    sortDirection: "",
  };
  constructor() {
    // console.log('called check', this._search$);
    
    this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._data$.next(result.data);
        this._total$.next(result.total);
      });
  
      this._search$.next();
      
  }
  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set sortColumn(sortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {sortColumn, sortDirection, pageSize, page} = this._state;

    // 1. sort
    let data = sort(MOCKS, sortColumn, sortDirection);

    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({data, total});
  }
}
