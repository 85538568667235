// ข้อมูลผู้ใช้งานระบบ

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortDirection } from "../../@theme/directives/sort.directive";
import {State } from "../data/complete";


export interface Mock {
    id: number;
    title: string;
    send_bid: string;
    pattern_send: string;
    time_send: string;
    status_send: string;
    send_real: string;
    emp_send: string;
    status: string;
    emp_edit: string;
    update_datetime: string;
  }
  const MOCKS :Mock[] = [
    {
      id: 1,
      title: "ลูกค้าให้ปรับราคา",
      send_bid: "ทั้งหมด",
      pattern_send: "ตั้งเวลาส่ง",
      time_send: "21/08/2021 09:00",
      status_send: "ยังไม่ได้ส่ง",
      send_real: "-",
      emp_send: "-",
      status: "ใช้งาน",
      emp_edit: "อัจฉรา สดใส",
      update_datetime: "21/08/2021 09:00"
    },
    {
      id: 2,
      title: "แข่งขันทางการตลาด",
      send_bid: "เพิ่มรายบุคคล",
      pattern_send: "ส่งทันที",
      time_send: "-",
      status_send: "ส่งแล้ว",
      send_real: "30/07/2021 13:23",
      emp_send: "สมสมัย วิทยา",
      status: "ใช้งาน",
      emp_edit: "อัจฉรา สดใส",
      update_datetime: "21/08/2021 09:00"
    },
    {
      id: 3,
      title: "โปรโมชั่น",
      send_bid: "เพิ่มรายบุคคล",
      pattern_send: "ตั้งเวลาส่ง",
      time_send: "-",
      status_send: "ส่งแล้ว",
      send_real: "30/07/2021 13:23",
      emp_send: "Auto Sent",
      status: "ใช้งาน",
      emp_edit: "อัจฉรา สดใส",
      update_datetime: "21/08/2021 09:00"
    },
    {
      id: 4,
      title: "โปรโมชั่น",
      send_bid: "ลูกค้าที่เข้าเงื่อนไข",
      pattern_send: "ตั้งเวลาส่ง",
      time_send: "-",
      status_send: "ส่งแล้ว",
      send_real: "30/07/2021 13:23",
      emp_send: "Auto Sent",
      status: "ใช้งาน",
      emp_edit: "อัจฉรา สดใส",
      update_datetime: "21/08/2021 09:00"
    },
    {
      id: 5,
      title: "โปรโมชั่น",
      send_bid: "ลูกค้าที่เข้าเงื่อนไข",
      pattern_send: "ตั้งเวลาส่ง",
      time_send: "-",
      status_send: "ส่งแล้ว",
      send_real: "30/07/2021 13:23",
      emp_send: "Auto Sent",
      status: "ใช้งาน",
      emp_edit: "อัจฉรา สดใส",
      update_datetime: "21/08/2021 09:00"
    },
  ]
  interface SearchResult {
    data: Mock[];
    total: number;
  }
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Mock[], column: any, direction: string): Mock[]{
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Injectable()
export class MService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<Mock[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 5,
    sortColumn: "",
    sortDirection: "",
  };
  constructor() {
    // console.log('called check', this._search$);
    
    this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._data$.next(result.data);
        this._total$.next(result.total);
      });
  
      this._search$.next();
      
  }
  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set sortColumn(sortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {sortColumn, sortDirection, pageSize, page} = this._state;

    // 1. sort
    let data = sort(MOCKS, sortColumn, sortDirection);

    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({data, total});
  }
}
