import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';



@Injectable()
export class AuthService {
    private url = environment.apiUrl;

    constructor(private http: HttpClient,
                private route: Router) {}
    login(login) {
       return this.http.post(this.url + `/auth/login`, login)
    }

    getToken() {
        let token = JSON.parse(localStorage.getItem('token'))
        return token || '';
    }

    logout() {
        let logout = localStorage.removeItem('token');
        if (logout == null) {
            this.route.navigate(['/auth/login'])
        }
    }

    get isLoggedIn(): boolean {
        let authToken = localStorage.getItem('token');
        return (authToken !== null) ? true : false;
    }

}