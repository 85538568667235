import { LayoutService } from './layout.service';
import { AnalyticsService } from './analytics.service';
import { PlayerService } from './player.service';
import { StateService } from './state.service';
import {AService} from './a.service';
import {UserBiddingService} from './user.bidding.service';
import {PlanBiddingService} from './plan-bidding.service';
import {CreditOnlineService} from './credit-online-bidding.service';
import {CashService} from './cash.service';
import {PermisstionBiddingService} from './permisstion-bidding.service';
import {ResultBiddingService} from './result-bidding.service';
import { CareerService } from './career.service';
import { SellerService } from './seller-finance.service';
import { IService } from './i.service';
import { JService } from './j.service';
import { KService } from './k.service';
import { LService } from './l.service';
import { MService } from './m.service';
import { NService } from './n.service';
import { AuthService } from './auth.service';
import { EmployeeService } from './employee.service';
import {AddressService} from './address.service';
import { UserService } from './user.service';
import { PlanBiddingOnlineService } from './plan-bidding-online.service';
import { CompanyService } from './company.service';
import { NotificationService } from './notification.service';
import { MainTenanceService } from './maintenace.service';
import { SettingService } from './setting.service';
import { BookingService } from './booking.service';
import { CarService } from './car.service';
import { CheckVehService } from './check-veh-form.service';
import { CommonService } from './common.service';
import { ContactUsService } from './contract-us.service';
import { CrmContactUserService } from './crm-contact-user.service';
import { EmpUserManagementService } from './emp-user-management.service';
import { ImgLoginService} from './img-login.service';
import { InterestService } from './interest.service';
import { PolicyService } from './policy.service';
import { QAndAService } from './q&a.service';
import { TermService } from './term-condition.service';
import { UserProfileService} from './user-profile.service';
import { AuthGuard }  from './auth.guard';
import { BrandService } from './brand.service';
export {
  LayoutService,
  AnalyticsService,
  PlayerService,
  StateService,
  AuthService,
  EmployeeService,
  AddressService,
  // new Service for called API !!!!!!
  AService,
  UserBiddingService,
  PlanBiddingService,
  CreditOnlineService,
  CashService,
  PermisstionBiddingService,
  ResultBiddingService,
  CareerService,
  SellerService,
  IService,
  JService,
  KService,
  LService,
  MService,
  NService,
  UserService,
  PlanBiddingOnlineService,
  CompanyService,
  NotificationService,
  MainTenanceService,
  SettingService,
  BookingService,
  CarService,
  CheckVehService,
  CommonService,
  ContactUsService,
  CrmContactUserService,
  EmpUserManagementService,
  ImgLoginService,
  InterestService,
  PolicyService,
  QAndAService,
  TermService,
  UserProfileService,
  AuthGuard,
  BrandService
};
