import { Pipe, PipeTransform } from '@angular/core';

import { es } from 'date-fns/locale';
import { differenceInMinutes, addMinutes, format } from 'date-fns'

@Pipe({ name: 'config_date' })
export class DatePipe implements PipeTransform {

  transform(input: string): any {
    if (input) {
        let newDate =   format(new Date(input), 'dd/MM/yyyy')
        return newDate;
    } else {
        return 'ไม่ระบุ';
    }
  }
}

@Pipe({ name: 'config_datetime' })
export class DateTimePipe implements PipeTransform {

  transform(input: string): any {
    if (input) {
        let newDate =   format(new Date(input), 'dd/MM/yyyy HH:mm:ss')
        return newDate;
    } else {
        return 'ไม่ระบุ';
    }
  }
}


@Pipe({ name: 'config_datetime_add30' })
export class DateTimePipeAdd30 implements PipeTransform {

  transform(input: string): any {
    if (input) {

        const addminutes = addMinutes(new Date(input), 30)
        const format_date = format(new Date(addminutes), 'dd/MM/yyyy HH:mm:ss')
        return format_date;

    } else {
      // console.log(input);
        return 'ไม่ระบุ';
    }
  }
}
