// ข้อมูลผู้ใช้งานระบบ

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class PermisstionBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(true);
  private plan_id = new BehaviorSubject(0);
  currentPlan = this.plan_id.asObservable();
  constructor(private http: HttpClient) {
  }
  changePlanId(message: number) {
    this.plan_id.next(message)
  }

  bidderList(data) {
    return this.http.post(this.url + '/bidder/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  getPdf(data) {
    return this.http.post(this.url + '/bidder/end-bidding', data,{responseType : "arraybuffer"})
      
  }
  getBankList(){
    return this.http.get(this.url + '/getbank')
  }

  getBidderById(id: number) {
    return this.http.get(this.url + `/bidder/${id}`)
  }

  updateBidder(data: any, id: number) {
    return this.http.put(this.url + `/bidder/${id}`, data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  addBidderExp(data) {
    return this.http.post(this.url + '/bidder', data)
  }
  addNoRefund(data){
    return this.http.post(this.url + '/bidder/noRefundToNextRound', data)
  }
  planAuctionById(id: number) {
    return this.http.get(this.url + `/plan_auction/${id}`)
  }
  planAuctionByName() {
    return this.http.get(this.url + '/plan_auction/name')
  }
  bidderExp(data) {
    return this.http.post(this.url + '/bidder/list/exp', data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }
  bidderNoRefund(data) {
    return this.http.post(this.url + '/bidder/list/noRefund', data)
    .pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  transferCompanyList(data) {
    return this.http.post(this.url + '/bidder/transfer-money/list/exp', data)
    .pipe(
      tap(() => this._loading2$.next(true)),
      delay(200),
      map((res) => {
        this._loading2$.next(false);
        return res;
      })
    )
  }

  addTransferCompany(data) {
    return this.http.post(this.url + '/bidder/transfer-money/add', data)
  }

  planAuction() {
    return this.http.get(this.url + '/plan_auction/name');
  }

  getLastedPlanByTransferMoneyId(id: number) {
    return this.http.get(this.url + `/transfer-money/plan_auctionlist/${id}`)
  }
  
  getPlanByTransferAtRefundCanJoin(id: number) {
    return this.http.get(this.url + `/transfer-money/planAucByRefundCanJoin/${id}`)
  }
  // planAuctionFinanceExp() {
  //   return this.http.get(this.url + `/company/plan_auction/next-round`);
  // }

  get loading$() { return this._loading$.asObservable(); }

  get loading2$() { return this._loading2$.asObservable(); }
}
