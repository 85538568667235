export interface PagableData<T> {
    total_items: number;
    total_pages: number;
    page_size: number;
    page: number;
    data: T;
}

export interface ApiResponse<T> {
    code: number;
    message: string;
    data: T;
}

export enum ActionTypeEnum {
    View = 1,
    Add_Edit = 2,
    Delete = 3,
}