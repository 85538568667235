import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ActionTypeEnum } from '../interface/common';
import { CommonService } from './common.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private router: Router,
    private commonService: CommonService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      let menu: any = route.routeConfig;
      const home_page = '/pages/home';
      this.commonService.getPermissionMenu().subscribe((res) => {
        if (res) {
          if (res.code == 1) {
            if (menu.action_type == ActionTypeEnum.View) {
              const find = res.data.find(x => x.menu_id == menu.menu_id && x.is_view);
              if (find) {
                resolve(true);
              } else {
                this.router.navigate([home_page]);
                resolve(false);
              }
            } else if (menu.action_type == ActionTypeEnum.Add_Edit) {
              const find = res.data.find(x => x.menu_id == menu.menu_id && x.is_add_edit);
              if (find) {
                resolve(true);
              } else {
                this.router.navigate([home_page]);
                resolve(false);
              }
            } else if (menu.action_type == ActionTypeEnum.Delete) {
              const find = res.data.find(x => x.menu_id == menu.menu_id && x.is_delete);
              if (find) {
                resolve(true);
              } else {
                this.router.navigate([home_page]);
                resolve(false);
              }
            }
          } else {
            this.router.navigate([home_page]);
            resolve(false);
          }

        } else {
          this.router.navigate([home_page]);
          resolve(false);
        }

      })
    })

  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
