import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Socket } from 'ngx-socket-io';
@Injectable()
export class PlanBiddingOnlineService {

  domain: string;
  currentPlanAuction = this.socket.fromEvent<any>('plan_info');
  currentVehAuction = this.socket.fromEvent<any>('veh_info');
  constructor(private http: HttpClient, private socket: Socket) {
    this.domain = environment.apiUrl;
  }
  getPlanInfoById(id: number) {
    const uri = `plan_auction_info/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<any>(url);
  }

  getVehInfoById(id: number) {
    const uri = `veh_auction_info/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<any>(url);
  }
  getPlanBiddingById(id: number, req) {
    const uri = `plan_auction_online/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  getBidsById(id) {
    const uri = `veh_auction_online_bids/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<any>(url);
  }

  getBidsByPlanId(id) {
    const uri = `veh_auction_online_bids_plan_id/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<any>(url);
  }
  getVehList(req) {
    const uri = `veh_auction_list`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }
  getVehListExcel(req) {
    const uri = `veh_auction_list_excel`;
    const url = `${this.domain}/${uri}`;
    return this.http.post(url, req, { responseType: 'arraybuffer' });
  }

  getPlanLastBidByIds(plan_auc_id: number, ids: string) {
    const uri = `veh_auction_online_last_bid/${plan_auc_id}/${ids}`;
    const url = `${this.domain}/${uri}`;

    return this.http.get<any>(url);
  }
  getBidderNameByPlanId(id: number) {
    const uri = `bidder_name_by_plan/${id}`;
    const url = `${this.domain}/${uri}`;

    return this.http.get<any>(url);
  }

  updateStartPrice(req) {
    const uri = `veh_auction/start_price`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<any>(url, req);
  }

  updateReservePrice(req) {
    const uri = `veh_auction/reserve_price`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<any>(url, req);
  }
  getUsersJoinAuctionByVehBidId(id: number, plan_auc_veh_id: number, plan_auc_id: number) {
    const uri = `users_join_auction/${id}/${plan_auc_veh_id}/${plan_auc_id}`;
    const url = `${this.domain}/${uri}`;

    return this.http.get<any>(url);
  }
  getDepositByVehBidId(id: number, plan_auc_veh_id: number, plan_auc_id: number) {
    const uri = `deposit/${id}/${plan_auc_veh_id}/${plan_auc_id}`;
    const url = `${this.domain}/${uri}`;

    return this.http.get<any>(url);
  }
  getPlanBiddingByIdSocket(id: number) {
    this.socket.emit('joinRoom', { room: `plan_${id}` });
  }
  getVehBiddingByIdSocket(id: number) {
    this.socket.emit('joinRoom', { room: `veh_${id}` });
  }

  destroyPlanBiddingByIdSocket(id: number) {
    this.socket.emit('leaveRoom', { room: `plan_${id}` });
  }
  destroyVehBiddingByIdSocket(id: number) {
    this.socket.emit('leaveRoom', { room: `veh_${id}` });
  }
}
