// ตั้งค่าแอปพลิเคชัน

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class SettingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {}

  setting() {
    return this.http.get(this.url + `/setting`);
  }
  updateSetting(data: any) {
    return this.http.put(this.url + `/setting`, data);
  }

}
