import { ContactUsStatus } from "./../interface/contact-us";
import { routes } from "../../auth/auth-routing.module";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, delay, map, retry, tap } from "rxjs/operators";
import { da } from "date-fns/locale";

@Injectable()
export class CarService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {
    this._loading$.next(false);
  }

  getDetail(data) {
    return this.http.post(this.url + "/vehicleBidding/id", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("detail", res);
        return res;
      })
    );
  }

  getImg(imgPath: any) {
    console.log("data", imgPath);
    return this.http.post(this.url + "/vehicleBidding/getImage", imgPath).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        console.log("detail", res);
        return res;
      })
    );
  }

  convertBlobToBase64(blob: Blob) {
    return Observable.create((observer) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        console.log("Image in Base64: ", event.target.result);
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        console.log("File could not be read: " + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

  getDetailArr(data) {
    return this.http.post(this.url + "/vehicleBidding/arr", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("detail", res);
        return res;
      })
    );
  }

  deleteCar(id, data: any) {
    // console.log("delete" , id, data);
    let car = this.http.put(this.url + `/vehicleBidding/delete/${id}`, data);
    // console.log(car);
    return car;
  }

  addCarDetail(data: any) {
    return this.http.post(this.url + `/vehicleBidding/addData`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        // console.log(res);
        this._loading$.next(false);
        return res;
      })
    );
  }
  deleteImg(data: any) {
    // console.log('d',data);
    let del = this.http.post(this.url + `/vehicleBidding/deleteImage`, data);
    return del;
  }

  addImage(id, data: any) {
    // console.log('data',data );
    return this.http
      .put(this.url + `/vehicleBidding/addImage/${id}`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          // console.log('update_img',res);
          return res;
        })
      );
  }

  getProvince() {
    return this.http.get(this.url + "/province");
  }

  getVehStatus() {
    return this.http.get(this.url + "/vehicle-report/vehStatus");
  }

  updateCar(id, data: any) {
    // console.log("updateCar" , id, data);
    return this.http.put(this.url + `/vehicleBidding/edit/${id}`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("updateCar",res);
        return res;
      })
    );
  }
  getCarlist(data) {
    // console.log("postData",data);
    return this.http.post(this.url + "/vehicleBidding/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log(res);
        return res;
      })
    );
  }

  getSellerFinanceList() {
    return this.http.get(this.url + "/sellerFinance/list");
  }

  getFinishPlanAuction() {
    return this.http.get(this.url + "/vehicle-report/getFinishPlanAuction");
  }

  getSellerFinanceId(data) {
    return this.http.post(this.url + "/sellerFinance/id", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  //source_veh
  getSource_veh(data) {
    return this.http
      .post(this.url + "/sourceVeh/getBySellerFinanceId", data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }
  getsourcevehList() {
    let ss = this.http.get(this.url + "/sourceVeh/getSourcevehlist");
    return ss;
  }

  updateStartPrice(id, data: any) {
    // console.log("editStartPriceData:", id, data);
    return this.http
      .put(this.url + `/vehicleBidding/editStartPrice/${id}`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          // console.log("startPrice",res);
          return res;
        })
      );
  }
  updateReservePrice(id, data: any) {
    // console.log("editReservePriceData:", id, data);
    return this.http
      .put(this.url + `/vehicleBidding/editReservePrice/${id}`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          // console.log("ReservePrice",res);
          return res;
        })
      );
  }
  getBranchyId(id) {
    let res = this.http.get(this.url + `/branch/${id}`);
    // console.log(res);
    return res;
  }

  //get year
  getYear() {
    return this.http.get(this.url + "/year");
  }

  getYearBuild() {
    return this.http.get(this.url + "/year/bu");
  }

  getGearType() {
    return this.http.get(this.url + "/geartType/list");
  }

  getDriveSystem() {
    return this.http.get(this.url + "/driveSystem/list");
  }

  getDriveSystemById(id: any) {
    let drive = this.http.get(this.url + `/driveSystem/byDriveSystemid/${id}`);
    // console.log("drive_from_api",drive);
    return drive;
  }
  getBrand() {
    return this.http.get(this.url + "/brand");
  }

  getModel(data) {
    return this.http.post(this.url + "/model", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  getSubModel(data) {
    // console.log(data);
    return this.http.post(this.url + "/submodel", data);
  }

  getColor() {
    return this.http.get(this.url + "/color");
  }
  getBranch() {
    return this.http.get(this.url + "/branch");
  }

  //priceLog
  getPriceStartLog(data: any) {
    return this.http
      .post(this.url + "/vehicleBidding/getPriceStartLog", data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          // console.log("getPriceStartLog", res);
          return res;
        })
      );
  }

  getPriceReserveLog(data: any) {
    return this.http
      .post(this.url + "/vehicleBidding/getPriceReserveLog", data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          // console.log("getPriceReserveLog", res);
          return res;
        })
      );
  }
  //save
  saveMaintainanceDetail(data) {
    return this.http.post(this.url + "/checkVehicleDetail/add", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  // //edit
  // updateMaintainanceDetail(data) {
  //   return this.http.put(this.url + "/checkVehicleDetail/edit", data).pipe(
  //     tap(() => this._loading$.next(true)),
  //     delay(200),
  //     map((res: any) => {
  //       this._loading$.next(false);
  //       return res;
  //     })
  //   );
  // }
  // //maintainance
  getMaintainanceDetail(data) {
    return this.http
      .post(this.url + "/checkVehicleDetail/getCheckVehFormDetail", data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }
  getMaintainanceList(data) {
    return this.http.post(this.url + "/checkVehicleForm/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  //redbook
  getFinanceList() {
    return this.http.get(this.url + "/finance/list");
  }
  getFinanceById(id) {
    return this.http.get(this.url + `/finance/${id}`);
  }

  getFinanceRedbookList(data) {
    // console.log("postData",data);
    return this.http.post(this.url + `/financeRedbook/list`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  addRedbook(data: any) {
    return this.http.post(this.url + "/financeRedbook/add", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        console.log(res);
        this._loading$.next(false);
        return res;
      })
    );
  }

  editRedbook(id, data: any) {
    console.log("from_front", data);
    return this.http.put(this.url + `/financeRedbook/edit/${id}`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        console.log(res);
        this._loading$.next(false);
        return res;
      })
    );
  }

  redbookById(id: number) {
    console.log(id);
    return this.http.get(this.url + `/financeRedbook/${id}`).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("detail", res);
        return res;
      })
    );
  }

  deleteRedbook(id) {
    // console.log("delete" , id, data);
    let redbook = this.http.delete(
      this.url + `/financeRedbook/delete/${id}`,
      id
    );
    // console.log(car);
    return redbook;
  }

  getVehicelAssetMaster() {
    return this.http.get(this.url + `/asset/getAssetMaster`).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  getVehicelAssetRecorder() {
    return this.http.get(this.url + `/asset/recorder`).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  getAssetById(data) {
    return this.http.post(this.url + "/asset/getAssetDetailByVehId", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("detail", res);
        return res;
      })
    );
  }

  addAsset(data: any) {
    return this.http.post(this.url + `/asset/addAsset`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        // console.log(res);
        this._loading$.next(false);
        return res;
      })
    );
  }

  updateAsset(id, data: any) {
    // console.log("updateCar" , id, data);
    return this.http.put(this.url + `/asset/updateAsset/${id}`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        // console.log("updateCar",res);
        return res;
      })
    );
  }

  //report รถเข้าประจำวัน
  getVehicleComimgData(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleComming`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }
  //report รถเข้าประจำวัน Excel
  getVehicleComimgDataExcel(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleCommingExcel`, data, {
        responseType: "blob",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }

  //report รายงานสรุปผลการประมูลขายทอดตลาด
  getVehicleFriedMarket(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleAuctionResult`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }
  //report รายงานสรุปผลการประมูลขายทอดตลาด Excel
  getVehicleFriedMarketExcel(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleAuctionResultExcel`, data, {
        responseType: "blob",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }

  //report รถคงเหลือ
  getVehicleRemaining(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleRemaining`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }
  //report รถคงเหลือ Excel
  getVehicleRemainingDataExcel(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleRemainingExcel`, data, {
        responseType: "blob",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }

  //report รถคงเหลือ Finance
  getVehicleSellFinanceFormat(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleSellFinance`, data)
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }

  //report รถคงเหลือ Finance Excel
  getVehicleSellFinanceFormatExcel(data: any) {
    return this.http
      .post(this.url + `/vehicle-report/getVehicleSellFinanceExcel`, data, {
        responseType: "blob",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }

  // report รายละเอียดการขาย

  getSalesReport(body): Observable<any> {
    return this.http.post(this.url + "/sales-result", body).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }

  getSalesReportExcel(body): Observable<any> {
    return this.http
      .post(this.url + `/sales-result-export`, body, {
        responseType: "arraybuffer",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }

  getEngineOil(): Observable<any> {
    return this.http.get(this.url + "/engine_oil")
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }

  getReportParking(body: any): Observable<any> {
    return this.http
      .post(this.url + `/report-parking`, body).pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }

  getReportParkingExcel(body: any): Observable<any> {
    return this.http
      .post(this.url + `/report-parking-export`, body, {
        responseType: "arraybuffer"
      }).pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }


  getcheckChassisNumberEdit(data) {
    // console.log("delete" , id, data);
    return this.http
      .post(this.url + `/vehicleBidding/checkChassisNumberEdit`, data).pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }

  getcheckChassisNumberAdd(data) {
    return this.http
      .post(this.url + `/vehicleBidding/checkChassisNumberAdd`, data).pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading$.next(false);
          return res;
        })
      );
  }

  get loading2$() {
    return this._loading2$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
}
