import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import {tap, map, delay } from "rxjs/operators";
import { environment } from "../../../environments/environment";


@Injectable()
export class SellerService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    this._loading$.next(false)
  }    

  getSellerFinanceById(id: number) {
    return this.http.post(this.url + '/sellerFinance/id', {seller_finance_id: id}).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }

  getSellerFinanceAccountById(id : number){
    //let sellerAcc: any = this.http.get(this.url + `/credit-online/${id}`);
    return this.http.get(this.url + '/sellerFinance/account/' + id);
  }
  // upDateCreditOnline(data: any, id: number) {
  //   return this.http.put(this.url + `/credit-online/${id}`, data)
  //   .pipe(
  //     tap(() => this._loading$.next(true)),
  //     delay(200),
  //     map((res) => {
  //       this._loading$.next(false);
  //       return res;
  //     })
  //   )
  // }


}
