import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, delay, map, retry, tap } from "rxjs/operators";

@Injectable()
export class InterestService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {
    this._loading$.next(false);
  }



  getFinanceList() {
    return this.http.get(this.url + "/seller_finance/list");
  }

  getCarType(){
    return this.http.get(this.url + "/car-type");

  }

  getLicenseType2(){
    return this.http.get(this.url + "/licenseType2");

  }

  getInterest(data :any){
    return this.http.post(this.url + "/interest/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );

  }

  addInterest(data:any){
    console.log("data",data);
    return this.http.post(this.url + "/interest/add", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    );
  }
  getInterestById(id){

    console.log(id);
    return this.http.get(this.url + `/interest/${id}`).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
       // console.log("detail", res);
        return res;
      })
    );
  }

  updateInterest(id,data : any){
    console.log("before",data);
    return this.http.put(this.url + `/interest/update/${id}`, data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
         console.log(res);
        this._loading$.next(false);
        return res;
      })
    );


  }


}
