
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, tap, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class CompanyService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(true);
  private _loading3$ = new BehaviorSubject<boolean>(true);
  private loadingPage = new BehaviorSubject(1);
  currentPage = this.loadingPage.asObservable();
  constructor(private http: HttpClient) { }

  changePage(message: number) {
    this.loadingPage.next(message)
  }
  companyList(data) {
    return this.http.post(this.url + "/company/list", data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        return res;
      })
    )
  }

  resetCompanyPassword(data){


     return this.http.put(this.url + `/company/resetpassword`,data).pipe(
      tap(() => this._loading$.next(true)),
      delay(200),
      map((res: any) => {
        this._loading$.next(false);
        console.log("res",res)
        return res;
      })

    )

  }
  userById(id:number) {
    console.log("id",id)
    return this.http.get(this.url + `/userbidding/userById/${id}`);

  }
  resetpin(data){
    return this.http.put(this.url + `/company/resetpin`,data).pipe(  tap(() => this._loading$.next(true)),
    delay(200),
    map((res: any) => {
      this._loading$.next(false);

      return res;
    }))
  }

  unlockTime(data:any) {
    console.log("data",data);
    return this.http.post(this.url + `/company/unlock`, data);
  }

  companyById(id: number) {
    return this.http.get(this.url + `/company/${id}`);
  }
  addCompanyProfile(data) {
    return this.http.post(this.url + "/company", data);
  }
  updateCompanyProfile(data) {
    return this.http.put(this.url + "/company", data);
  }
  updateCompanyProfile1(data) {
    return this.http.put(this.url + "/company1", data);
  }

  addTransferMoney(data) {
    return this.http.post(this.url + "/company/transfer-money", data);
  }

  uploadDocument6file(data) {
    return this.http.post(this.url + "/company-document", data);
  }
  companyDoc1() {
    return this.http.get(this.url + "/company-doc1");
  }
  companyDoc2() {
    return this.http.get(this.url + "/company-doc2");
  }

  bank() {
    return this.http.get(this.url + "/getbank");
  }

  employeesList() {
    return this.http.get(this.url + "/employee/list");
  }

  // transfer list

  transferList(data) {
    return this.http.post(this.url + "/transfer-list", data)
      .pipe(
        tap(() => this._loading2$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading2$.next(false);
          return res;
        })
      )
  }
  transferById(id: number) {
    return this.http.get(this.url + `/transfer/${id}`);
  }

  updateTransferMoney(data) {
    return this.http.put(this.url + `/transfer`, data);
  }

  deleteTransfer(id) {
    return this.http.delete(this.url + `/delete/transfer/${id}`)
  }


  // Vip

  vipList(data) {
    return this.http.post(this.url + `/vip/company-list`, data)
      .pipe(
        tap(() => this._loading3$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading3$.next(false);
          return res;
        })
      )
  }

  vipListById(data) {
    return this.http.post(this.url + `/vip/company-vipListById`, data)
      .pipe(
        tap(() => this._loading3$.next(true)),
        delay(200),
        map((res: any) => {
          this._loading3$.next(false);
          return res;
        })
      )
  }

  addVip(data) {
    return this.http.post(this.url + `/vip/company`, data);
  }

  updateVip(data) {
    return this.http.put(this.url + `/vip/company`, data);
  }

  deleteVip(data) {
    return this.http.post(this.url + `/vip/company/del`,data);
  }

  planAuctionByName() {
    return this.http.get(this.url + '/plan_auction/name')
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get loading2$() {
    return this._loading2$.asObservable();
  }
  get loading3$() {
    return this._loading3$.asObservable();
  }

  getRecommendSource() {
    return this.http.get(this.url + '/recommend-source');
  }
  getRecommendEmp() {
    return this.http.get(this.url + '/recommend-emp');
  }
  getDefaultMaxVeh() {
    return this.http.get(this.url + '/default-max-veh');
  }

}
