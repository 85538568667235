import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { EmpUserInfo, MenuGroupInfo, MenuInfo } from '../interface/emp-user-management';
import { ApiResponse, PagableData } from '../interface/common';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
@Injectable()
export class UserProfileService {
  domain: string;
  currentUserProfile = this.socket.fromEvent<any>('user_profile');
  currentPermissionMenu = this.socket.fromEvent<any>('permission_menu');
  currentPermissionMenuUser = this.socket.fromEvent<any>('permission_menu_user');
  constructor(private http: HttpClient, private socket: Socket, private router: Router) {
    this.domain = environment.apiUrl;
  }

  async logout() {
    let out = await this.logoutEmp().toPromise();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login']);
  }

  logoutEmp(): Observable<any> {
    const uri = `auth/logout`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, {});
  }

  getUserProfile() {
    const uri = `user-profile`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<EmpUserInfo>>(url);
  }
  updateUserProfile(emp_id: number, firstname: string, lastname: string) {
    const req = { emp_id: emp_id, firstname: firstname, lastname: lastname };
    const uri = `user-profile`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<ApiResponse<any>>(url, req);
  }

  updatePasswordUserProfile(emp_id: number,prov_password: string, password: string, confirm_password: string) {
    const req = { emp_id: emp_id, prov_password: prov_password, password: password, confirm_password: confirm_password };
    const uri = `user-profile-change-password`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<ApiResponse<any>>(url, req);
  }

  getUserProfileByEmpIdSocket(id: number) {
    this.socket.emit('joinRoom', { room: `user_profile_${id}` });
  }

  getPermissionMenuByEmpIdSocket(id: number) {
    this.socket.emit('joinRoom', { room: `permission_menu_${id}` });
  }

  getPermissionMenuUserByEmpIdSocket(id: number) {
    this.socket.emit('joinRoom', { room: `permission_menu_user_${id}` });
  }

  destroyUserProfileByEmpIdSocket(id: number) {
    this.socket.emit('leaveRoom', { room: `user_profile_${id}` });
  }

  destroyPermissionMenuByEmpIdSocket(id: number) {
    this.socket.emit('leaveRoom', { room: `permission_menu_${id}` });
  }

  destroyPermissionMenuUserByEmpIdSocket(id: number) {
    this.socket.emit('leaveRoom', { room: `permission_menu_user_${id}` });
  }
}