// ข้อมูลจัดการผลการประมูล

import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";


@Injectable()
export class ResultBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private seller_finance_id = new BehaviorSubject(0);
  currentseller_finance_id = this.seller_finance_id.asObservable();

  // chage plan_auc_id
  private plan_auc_id = new BehaviorSubject(0);
  current_plan_auc_id = this.plan_auc_id.asObservable();
  constructor(private http: HttpClient) {
  }
  setSellerFiananceId(seller_finance_id: number) {
    this.seller_finance_id.next(seller_finance_id);
  }

  setPlanId(plan_auc_id: number) {
    this.plan_auc_id.next(plan_auc_id);
  }
  resultBiddingList(data: any) {
    return this.http.post(this.url + '/result-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  carNotSellList(data: any) {
    console.log("before", data);
    return this.http.post(this.url + '/result-bidding/carlistNotSell', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )

  }
  updateCarStatus(data: any) {
    console.log("before", data);
    return this.http.post(this.url + '/result-bidding/updateCarToReadyStatus', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  // updateAutoCarToReady(data: any) {

  //   return this.http.post(this.url + '/result-bidding/updateAutoCarToReady', data).pipe(
  //     tap(() => this._loading$.next(true)
  //     ),
  //     delay(200),
  //     map((res: any) => {
  //       this._loading$.next(false)
  //       return res;
  //     })
  //   )
  // }

  sentemailResultBidding(data: any) {
    return this.http.post(this.url + '/result-bidding/sentemailResultBidding', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }


  generateReport(data: any) {
    return this.http.post(this.url + '/result-bidding/genReport', data, { responseType: 'arraybuffer' })
  }
  getReport(data: any) {
    console.log("sent", data)
    return this.http.post(this.url + '/result-bidding/report', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }


  seqBiddingList(data: any) {
    return this.http.post(this.url + '/seq-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }
  seqBiddingListById(data: any) {
    return this.http.post(this.url + '/seq-bidding/ById', data)
  }

  logBiddingList(data: any) {
    return this.http.post(this.url + '/log-bidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  dowLoadLogExcel(data: any) {
    return this.http.post(this.url + '/log-bidding/export/excel', data, { responseType: 'arraybuffer' })
  }
  // planAuctionByName() {
  //   return this.http.get(this.url + '/plan_auction/name')
  // }


  updateRemark(data) {
    return this.http.post(this.url + '/seq-bidding/update/remark', data)
  }


  resultBiddingById(data: any) {

    return this.http.post(this.url + '/result-bidding/byId', data)
  }

  resultEndBidding() {
    return this.http.get(this.url + `/result-plan/end-bidding`);
  }

  resultGetPlanAuction() {
    return this.http.get(this.url + `/result-bidding/getplanauction`);
  }

  getJoinAuctionReport(data: any) {
    console.log("sent", data)
    return this.http.get(this.url + `/result-bidding/getjoinauctionreport/${data}`).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  generateReportJoinAuction(data: any) {
    return this.http.get(this.url + `/result-bidding/genreportjoinauction/${data}`, { responseType: 'arraybuffer' })
  }


  vehicleToReady(data: any) {
    return this.http.post(this.url + '/seq/to_ready', data);
  }
  get loading$() { return this._loading$.asObservable(); }


  getReportResultBiddingCar(data: any) {
    console.log("sent", data)
    return this.http.post(this.url + '/result-bidding/reportResultBiddingCar', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  getReportResultBiddingCarByID(data: any) {
    console.log("sent", data)
    return this.http.post(this.url + '/result-bidding/reportResultBiddingCarByID', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  checkBookingCount(data: any) {
    //return this.http.get(this.url + `/seq/checkBooked/${id}`)
    return this.http.post(this.url + '/seq/checkBooked', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  getLogCarStatusChange(data) {
    return this.http.post(this.url + "/log-car-status-change/list", data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
    }

  genReportLogCarStatusChange(data: any) {
    return this.http
      .post(this.url + `/log-car-status-change/generateLogCarStatusChange`, data, {
        responseType: "blob",
      })
      .pipe(
        tap(() => this._loading$.next(true)),
        delay(200),
        map((res: any) => {
          // console.log(res);
          this._loading$.next(false);
          return res;
        })
      );
  }
}
