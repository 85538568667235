import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";


@Injectable()
export class TermService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    this._loading$.next(false);
  }
  getTermandConditionList(data) {

    let list = this.http.post(this.url + "/termAndConBidding/list", data);
    this._loading$.next(false);
    return list;
  }
  getLastedVersion(type) {

    let ss = this.http.get(this.url + `/termAndConBidding/lastVersion/${type}`);

    return ss;
  }

  getLastedWebviewVersion(type) {

    let ss = this.http.get(this.url + `/webview/termAndConBidding/lastVersion/${type}`);

    return ss;
  }


  getDetail(id) {
    return this.http.get(this.url + `/termAndConBidding/${id}`);


  }


  addDetail(data: any) {
    let res = this.http.post(this.url + `/termAndConBidding/add`, data);

    // console.log(res);
    return res;
  }


    updateDetail(id,data: any) {
      let res = this.http.put(this.url + `/termAndConBidding/edit/${id}`, data);

      return res;


    }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  get loading$() {
    return this._loading$.asObservable();
  }
}

function throwError(arg0: string) {
  throw new Error("Function not implemented.");
}
function tap(arg0: () => void): import("rxjs").OperatorFunction<Object, unknown> {
  throw new Error("Function not implemented.");
}

function delay(arg0: number): import("rxjs").OperatorFunction<unknown, unknown> {
  throw new Error("Function not implemented.");
}

