import { ContactUsStatus } from "./../interface/contact-us";
import { routes } from "../../auth/auth-routing.module";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError, delay, map, retry, tap } from "rxjs/operators";
import { da } from "date-fns/locale";


@Injectable()
export class NotificationService {
  private _loading = new BehaviorSubject<boolean>(true);

  private url = environment.apiUrl;

  constructor(private http: HttpClient,
              private route: Router) {

    this._loading.next(false);
  }
  notificationList(data) {
    return this.http.post(this.url + `/notiBackOffice/list`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  customerNotification(data){
    return this.http.post(this.url + `/notibackOffice/getCusNoti`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  getNotiBackById(id){
    return this.http.get(this.url + "/notiBackOffice/getByNotiId/" + id)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  deletetNotiBack(id){
    return this.http.get(this.url + "/notiBackOffice/delete/" + id)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  editCustomerNotification(data, id){
    return this.http.put(this.url + `/notiBackOffice/edit/` + id, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  getBidderCustomerNotification(data){
    return this.http.post(this.url + `/notiBackOffice/getBidder`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }

  addCustomerNotification(data){
    return this.http.post(this.url + `/notiBackOffice/add`, data)
    .pipe(
      tap(() => this._loading.next(true)),
      delay(200),
      map((res) => {
        this._loading.next(false);
        return res;
      })
    )
  }



  //log noti
getLognotiList(data){

  return this.http.post(this.url + `/notibackOffice/logNotiList`,data)
  .pipe(
    tap(() => this._loading.next(true)),
    delay(200),
    map((res) => {
      this._loading.next(false);
      return res;
    })
  )
}

exportexcel() {
  return this.http.get(this.url + '/notibackOffice/logNotiListExcel', { responseType: 'arraybuffer' })
}


  get loading() { return this._loading.asObservable(); }
}
