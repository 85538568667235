// ข้อมูลผู้ประมูล

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class UserBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {}

  userBiddingList(data) {
    return this.http.post(this.url + '/userbidding/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  userBiddingById(id) {
    console.log("id",id)
    return this.http.get(this.url + `/userbidding/${id}`);
  }

  updateBidding(data: any) {
    return this.http.put(this.url + `/userbidding/update`, data);
  }

  userBiddingBlackList(data) {
    return this.http.post(this.url + '/userbidding-black/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }
  addUserBiddingVIPBlack(data) {
    return this.http.post(this.url + `/userbidding/add`, data);
  }
  delBiddingVIPBlack(data: number) {
    return this.http.post(this.url + `/userbidding/del`, data);
  }
  unlockTime(data) {
    return this.http.post(this.url + `/userbidding/unlock`, data);
  }

  deleteUserBidder(id){
    return this.http.put(this.url + `/user/deleteCustomerAccount/${id}`, null);
  }

  get loading$() { return this._loading$.asObservable(); }

}
