import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { EmpUserInfo, OmaBranchInfo, PermissionInfo, ReqGetEmpUser, ReqAddEmpUser } from '../interface/emp-user-management';
import { ApiResponse, PagableData } from '../interface/common';

@Injectable()
export class EmpUserManagementService {
  domain: string;
  search_data: any;
  constructor(private http: HttpClient) {
    this.domain = environment.apiUrl;
  }

  setSearch(data) {
    this.search_data = data;
  }

  getEmpUserList(req?: ReqGetEmpUser) {
    const uri = `emp-user-list`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<PagableData<EmpUserInfo[]>>>(url, req);
  }
  getEmpMenuList(permission_id: number) {
    const uri = `emp-menu-list/${permission_id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<any[]>>(url);
  }
  getEmpUserById(id: number) {
    const uri = `emp-user/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<EmpUserInfo>>(url);
  }
  addEmpUser(req: ReqAddEmpUser) {
    const uri = `emp-user`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<any>>(url, req);
  }
  updateEmpUser(req: ReqAddEmpUser) {
    const uri = `emp-user`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<ApiResponse<any>>(url, req);
  }

  deleteEmpUser(emp_id: number) {
    const uri = `emp-user`;
    const url = `${this.domain}/${uri}/${emp_id}`;
    return this.http.delete<ApiResponse<any>>(url);
  }
  updatePasswordEmpUser(emp_id: number, password: string) {
    const req = { emp_id: emp_id, password: password };
    const uri = `emp-user-change-password`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<ApiResponse<any>>(url, req);
  }

  getOmaBranchList() {
    const uri = `oma-branch-list`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<OmaBranchInfo[]>>(url);
  }

  getPermissionList() {
    const uri = `emp-permission-list`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<PermissionInfo[]>>(url);
  }
}