// ข้อมูลแผนการประมูล

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { da } from "date-fns/locale";
import { BehaviorSubject, Observable } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class PlanBiddingService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loading2$ = new BehaviorSubject<boolean>(false);
  private shard_plan = new BehaviorSubject(0);
  currentPlan = this.shard_plan.asObservable();
  constructor(private http: HttpClient) {
  }
  // shard plan
  changePlan(plan_auc_id: number) {
    this.shard_plan.next(plan_auc_id)
  }
  // plan bidding 
  planBiddingList(data) {
    return this.http.post(this.url + '/plan_auction/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  updateplanBiddingList(data) {
    return this.http.put(this.url + `/plan_auction`, data)
  }

  addData(data) {
    return this.http.put(this.url + `/plan_auction/add_car`, data)
  }

  deleteData(data) {
    return this.http.put(this.url + `/plan_auction/delete_car`, data)
  }

  addPlanBidding(data) {
    return this.http.post(this.url + `/plan_auction`, data)
  }

  planBiddingListById(id: number) {
    return this.http.get(this.url + `/plan_auction/${id}`)
  }

  vehicleInplanBiddingList(data) {
    return this.http.post(this.url + `/plan_auction/vehicle`, data).pipe(
      tap(() => this._loading2$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading2$.next(false)
        return res;
      })
    )
  }
  planVehicleById(data: any) {
    return this.http.post(this.url + `/plan_auction/vehicle_byId`, data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }
  updateVehicleInPlan(data: any) {
    return this.http.put(this.url + '/plan_auction/vehicle', data)
  }

  // get branch()
  getBranch() {
    return this.http.get(this.url + '/branch');
  }
  getYearReg() {
    return this.http.get(this.url + '/year');
  }
  getYear() {
    return this.http.get(this.url + '/year/bu');
  }
  getBrand() {
    return this.http.get(this.url + '/brand');
  }
  getModel(data) {
    return this.http.post(this.url + '/model', data);
  }
  getSubmodel(data) {
    return this.http.post(this.url + '/submodel', data);
  }
  getColor() {
    return this.http.get(this.url + '/color');
  }

  getProvince() {
    return this.http.get(this.url + '/province');
  }

  getDropdown(data) {
    return this.http.post(this.url + '/result-bidding/dropdown', data);
  }

  getFinance() {
    return this.http.get(this.url + `/sellerFinance/list`);
  }

  getCatalog(filename) {
    //console.log(`/seq-bidding/getVehicleCatalog?seller_finance_id=${seller_finance_id}&plan_auc_id=${plan_auc_id}`);
    return this.http.get(filename, { responseType: 'arraybuffer' });
  }

  getPlanAuction(): Observable<any> {
    return this.http.get(this.url + '/plan_auction')
  }
  getProxyReport(data: any) {
    console.log("sent", data)
    return this.http.post(this.url + '/proxy-report', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
    )
  }

  ProxyReportExportExcel(data: any) {
    return this.http.post(this.url + `/proxy-report/exportExcel`, data, { responseType: 'arraybuffer' })
  }

  get loading$() { return this._loading$.asObservable(); }
  get loading2$() { return this._loading2$.asObservable(); }

}
