<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="bars" pack="fa"></nb-icon>
    </a>
    <a fxShow="true" fxHide.lt-md="true" (click)="navigateHome()" class="title--txt">Backoffice Omakase Car Auction</a>

    <!-- <a class="logo" href="#" (click)="navigateHome()">Backoffice Omakase Car Auction</a> -->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-action  fxShow="true" fxHide.lt-md="true" >{{employee?.emp_code}}&nbsp;&nbsp;<span>{{employee?.firstname}} &nbsp; {{employee?.lastname}}</span></nb-action>
      <nb-icon [nbContextMenu]="userMenu"  icon="caret-down" nbContextMenuTag="my-context-menu"></nb-icon>
    </nb-action>
  </nb-actions>
</div>
