import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class BrandService {
  domain: string;

  constructor(private http: HttpClient) {
    this.domain = environment.apiUrl;
  }

  getBrandList(req?: any) {
    const uri = `allbrand`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  getBrandById(id:number) {
    const uri = `brandbyid`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.post<any>(url, null);
  }

  addBrand(req?: any) {
    const uri = `brand_m`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  updateBrand(id:number,req?: any) {
    const uri = `brand_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.put<any>(url, req);
  }

  delBrand(id?: number) {
    const uri = `brand_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.delete<any>(url);
  }

  getModelList(req?: any) {
    const uri = `allmodel`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  getModelById(id:number) {
    const uri = `modelbyid`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.post<any>(url, null);
  }

  getModelByBrandId(req :any) {
    const uri = `model`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  addModel(req?: any) {
    const uri = `model_m`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  updateModel(id:number,req?: any) {
    const uri = `model_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.put<any>(url, req);
  }

  delModel(id?: number) {
    const uri = `model_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.delete<any>(url);
  }

  getSubmodelList(req?: any) {
    const uri = `allsubmodel`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  getSubmodelById(id:number) {
    const uri = `submodelbyid`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.post<any>(url, null);
  }


  addSubmodel(req?: any) {
    const uri = `submodel_m`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<any>(url, req);
  }

  updateSubmodel(id:number,req?: any) {
    const uri = `submodel_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.put<any>(url, req);
  }

  delSubmodel(id?: number) {
    const uri = `submodel_m`;
    const url = `${this.domain}/${uri}/${id}`;
    return this.http.delete<any>(url);
  }


}
