import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  NbAutocompleteComponent,
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbIconLibraries,
  NbInputModule,
  NbDatepickerModule,
  NbCheckboxModule,
  NbListModule,
  NbRadioModule,
  NbFormFieldModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbTabsetModule,
  NbAutocompleteModule,
  NbToggleModule


} from '@nebular/theme';
import {NbMomentDateModule} from '@nebular/moment'
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  PhonePipe,
  DatePipe,
  DateTimePipe,
  DateTimePipeAdd30,
  SafeHtmlPipe
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  AuthColumnLayoutComponent,
  HomeColumnLayoutComponent
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader } from './directives/sort.directive';
import { NumberDirective } from './directives/numbers-only.directive';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { th } from 'date-fns/locale';
import { ImageViewerModule } from "ngx-image-viewer";
import {QRCodeModule } from 'angularx-qrcode';

const NB_MODULES = [

  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbInputModule,
  NbDatepickerModule,
  NbMomentDateModule,
  NbDateFnsDateModule,
  NbCheckboxModule,
  NbListModule,
  NbRadioModule,
  NbFormFieldModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbAutocompleteModule,
  NbToggleModule,
  // image viewer
  ImageViewerModule,
  NbTabsetModule
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  AuthColumnLayoutComponent,
  HomeColumnLayoutComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  PhonePipe,
  DatePipe,
  DateTimePipe,
  DateTimePipeAdd30,
  SafeHtmlPipe
];

const FROMMODULE =[
  ReactiveFormsModule,
  FormsModule,
]

const NB_BOOSTRAP = [
  NgbModule,

]

const DIRECTIVE = [
  NgbdSortableHeader,
  NumberDirective
]
const OTHER = [

  QRCodeModule
]
@NgModule({
  imports: [ CommonModule, ...NB_MODULES, ...FROMMODULE, ...NB_BOOSTRAP, ...OTHER],
  exports: [CommonModule, ...DIRECTIVE, ...PIPES, ...COMPONENTS, ...FROMMODULE, NB_MODULES, ...NB_BOOSTRAP, ...OTHER],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVE],
})
export class ThemeModule {
  constructor(private iconsLibrary: NbIconLibraries) {
    this.iconsLibrary.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
    this.iconsLibrary.setDefaultPack('fa')
  }
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'corporate',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME, NbAutocompleteComponent ],
        ).providers,
        DecimalPipe
      ],
    };
  }
}
