// แบบฟอร์มการตรวจสภาพ

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class CheckVehService {
  private url = environment.apiUrl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {}

  getCheckVehList(data) {
      // console.log(data);
    return this.http.post(this.url + '/checkVehicleForm/list', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }

  loadImg(urll){
    return this.http.get(urll);
  }
  getCheckVehicleById(id){
    return this.http.get(this.url + '/checkVehicleForm/getCheckVehById/' + id);
  }

  getCheckVehicleTopSeq(){
    return this.http.get(this.url + '/checkVehicleForm/getTopSeq');
  }
  
  checkVehAdd(data) {
    return this.http.post(this.url + '/checkVehicleForm/add', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }

  checkVehEdit(data) {
    return this.http.put(this.url + '/checkVehicleForm/update/', data).pipe(
      tap(() => this._loading$.next(true)
      ),
      delay(200),
      map((res: any) => {
        this._loading$.next(false)
        return res;
      })
      )
  }

  get loading$() { return this._loading$.asObservable(); }

}
