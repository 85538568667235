import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ContactTimeInfo, ContactUserInfo, EmployeeInfo, ReqGetContactUser, ReqUpdateContactUser, SuggestTypeInfo, CarTypeInfo, ProvinceInfo } from '../interface/crm-contact-user';
import { ApiResponse, PagableData } from '../interface/common';

@Injectable()
export class CrmContactUserService {
  domain: string;
  search_data:any;
  constructor(private http: HttpClient) {
    this.domain = environment.apiUrl;
  }

  setSearch(data){
    this.search_data = data;
  }

  getContactUserList(req?: ReqGetContactUser) {
    const uri = `contact-user-list`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<PagableData<ContactUserInfo[]>>>(url, req);
  }

  getContactUserById(id: number) {
    const uri = `contact-user-detail/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<ContactUserInfo>>(url);
  }

  updateContactUser(req: ReqUpdateContactUser) {
    const uri = `contact-user`;
    const url = `${this.domain}/${uri}`;
    return this.http.put<ApiResponse<any>>(url, req);
  }

  getContactUserEmployee() {
    const uri = `contact-user-employee`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<EmployeeInfo[]>>(url);
  }

  getSuggestType() {
    const uri = `suggest-type`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<SuggestTypeInfo[]>>(url);
  }

  getContactTime() {
    const uri = `contact-time`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<ContactTimeInfo[]>>(url);
  }

  getCarType() {
    const uri = `car-type`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<CarTypeInfo[]>>(url);
  }

  getProvince() {
    const uri = `contact-user-province`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<ProvinceInfo[]>>(url);
  }
}